.flowContent {
  margin-top: 100px;
  padding: 1rem;
  overflow: hidden;
  h3 {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    color: #000;
    margin-bottom: 63px;
  }
  .content {
    margin: 0 auto;
    max-width: 1060px;
    border-radius: 12px;
    padding: 44px 36px;
    background-color: #e7f2e9;

    .flowList {
      display: flex;
      justify-content: space-between;
      .flowItem {
        display: flex;
        flex: 1;
        .spaceFlow {
          display: flex;
          align-items: center;
          margin-bottom: 32px;
          > img {
            margin: 0 10px;
          }
        }

        .flowText {
          width: 100%;
          padding-left: 6px;
          color: var(--app-text-60-color);
          div {
            font-size: 24px;
            font-weight: 500;
            color: #000;
          }
          p {
            // width: 190px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .flowContent {
    h3 {
      font-size: 26px;
      font-weight: 700;
      // color: var(--other-text-color);
      margin-bottom: 43px;
    }
    .content {
      .flowList {
        display: flex;

        .flowItem {
          .spaceFlow {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            > img {
              margin-left: 5px;
            }
            .line {
              width: 110px;
            }
            .done {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .flowContent {
    h3 {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 43px;
    }
    .content {
      overflow: hidden;
      .flowList {
        flex-direction: column;

        .flowItem {
          display: flex;
          .spaceFlow {
            flex-direction: column;
            align-items: start;
            margin-bottom: 18px;
            margin-right: 34px;
            > img {
              margin: 0;
              margin-bottom: 24px;
            }
          }
        }
      }
    }
  }
}

